<template lang="pug">
	.widget
		el-card.box-card.text-left
			.clearfix.d-flex.justify-content-start(slot="header")
				.d-flex.justify-content-between.w-100
					el-col.text-left(:span="24")
						h3 <b> About Search-Box </b>
			el-row(:gutter="24")
				el-col(:span="24")
					p <b>Search-Box</b> is an application which aims to help businesses with integrations, CRM system and Backoffice. The application consists of 3 parts:
					ul
						li Statistics
						li CRM
						li Integrations management
					p <b>Search-Box</b> application is built and maintained by {{vendor}}. <b>Search-Box</b> first appeared at 2015 and serves hundreds of happy clients in more then 50 countries
			el-divider

			el-row()
				el-col(:xs="24" :sm="12")
					h5 <b>Contact Form - Support</b>
					span Do not hesitate to ask any question, our team of professionals will be glad to help you
			div(v-if="!disabled")
				el-row()
					el-form.mt-4(ref="form" :model="form" :rules="rules" layout="vertical")
						el-row(:gutter="12")
							el-col(:span="24"  :md="12")
								el-form-item(has-feedback="" label="Topic" prop="topic")
									el-input(type="text" size="mini" show-word-limit="" maxlength="100" v-model="form.topic" :disabled="disabled")
						el-row(:gutter="12")
							el-col(:span="24"  :md="12")
								el-form-item(has-feedback="" label="Content" prop="content")
									el-input(type="textarea" size="mini" show-word-limit="" :rows="4" maxlength="3000" v-model="form.content"  :disabled="disabled")
						el-row(:gutter="12")
							el-col(:span="24"  :md="12")
								.d-flex.justify-content-center
									el-button(type="success" @click.prevent.native="submit"  :disabled="disabled") Submit Form
									el-button(type="danger" @click.prevent.native="resetForm"  :disabled="disabled") Reset Form
			div(v-else="")
				el-row(:gutter="12")
					el-col(:span="24"  :md="12")
						.text-center.m-5
							h1#thankyou Thank You!

</template>


<style lang="scss">
#thankyou {
	font-family: "SCRIPTIN";
	font-weight: bold;
	font-size: 70px;
	color: #152e53;
	text-shadow: 8px 20px 10px rgb(12, 27, 49, 0.3);

}
</style>


<script lang="js">
import { validateAlphaNumeric } from "@/utils/validate";

export default {
	data() {
		return {
			disabled: false ,
			form: {
				topic: '',
				content: ''
			},
			rules: {
				topic: { required: true, validator: validateAlphaNumeric, trigger: 'blur' },
				content: { required: true, validator: validateAlphaNumeric, trigger: 'blur' }
			}
		}
	},
	computed: {
		vendor() {
			return process.env.VUE_APP_VENDOR || 'ZGPM'
		}
	},
	methods: {
		resetForm() {
			this.form = {
				topic: '',
				content: ''
			}
		},
		submit() {
			this.$refs.form.validate(async valid => {
				if (!valid) return this.$message.error({title: 'Validation Error', message: 'The input form values are invalid. Please, refer to the errors in red'});
				this.$apix.sendHttpRequest('POST', 'support/submit', {topic: this.form.topic, content: this.form.content})
						.then(res => {
							this.disabled = true
						})
			});
		}
	}
}
</script>
